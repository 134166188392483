export default class UserModel {
  constructor(){
    this.objRaw = {};
    this.accountId = '';
    this.role = '';
    this.role_detail = '';
    this.is_family_leader = true;
    this.is_under_fourteen = true;
    this.legal_representation_id = '';
    this.legal_representation_update_at = '';
    this.name = '';
    this.status = '';
    this.updatedAt = '';
    this.registeredAt = '';
    this.phoneNumber = '';
    this.email = '';
    this.family = {};
    this.walletAmount = null;
    this.marketingConsent = true;
    this.marketingConsentAt = '';
    this.cs_list = [];
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, accountId, role, is_family_leader, isUnderFourteen, legal_representation_id, legal_representation_update_at, name, status, updatedAt, registeredAt, phoneNumber, email, family, walletAmount, card_list, card_apply_list, marketingConsent, marketingConsentAt, cs_list } = obj;
    this.accountId = accountId;
    this.role = role;
    if(role === 'CHILD' && isUnderFourteen){
      this.role_detail = 'CHILD_UNDER_FOURTEEN'
    }else{
      this.role_detail = role
    }
    
    this.is_family_leader = is_family_leader;
    this.is_under_fourteen = isUnderFourteen;
    this.legal_representation_id = legal_representation_id;
    this.legal_representation_update_at = legal_representation_update_at;
    this.name = name;
    this.status = status;
    this.updatedAt = updatedAt;
    this.registeredAt = registeredAt;
    this.phoneNumber = phoneNumber;
    this.email = email;
    if(family.length > 0){
      const selfIndex = family.findIndex(item => item.userId === id);
      family[selfIndex].isSelf = true;
      const leader = family.find(item => item.permission === 'LEADER');
      const unLeaderParent = family.find(item => item.role === 'PARENTS' && item.permission === 'MEMBER');
      const childrens = family.filter(item => item.role === 'CHILD');
      this.family = {
        LEADER : leader,
        UN_LEADER_PARENT : unLeaderParent,
        CHILDRENS : childrens
      };
    }
    this.walletAmount = walletAmount;
    this.card_list = card_list;
    this.card_apply_list = card_apply_list;
    this.marketingConsent = marketingConsent;
    this.marketingConsentAt = marketingConsentAt;
    this.cs_list = cs_list || [];
  }
}